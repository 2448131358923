.visually-hidden {
  @include visually-hidden();
}

.button-reset {
  @include button-reset();
}

.h-500-hvy {
  @include size-headline(500, 'heavy');
}
.h-500-md {
  @include size-headline(500, 'medium');
}
.h-500-rg {
  @include size-headline(500, 'regular');
}

.sh-400-hvy {
  @include size-subheadline(400, 'heavy');
}
.sh-300-md {
  @include size-subheadline(300, 'medium');
}

.p-600-md {
  @include size-paragraph(600, 'medium');
}
.p-500-hvy {
  @include size-paragraph(500, 'heavy');
}
.p-400-hvy {
  @include size-paragraph(400, 'heavy');
}
.p-400-rg {
  @include size-paragraph(400, 'regular');
}
.p-300-rg {
  @include size-paragraph(300, 'regular');
}
.l-500-md {
  @include size-label(500, 'medium');
}

.mono-grey {
  color: var(--mono-grey);
}

.text-right {
  text-align: right;
}

.flex-row {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);

  @include bp-min('sm') {
    flex-direction: row;
  }

  &-2xs {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xs);

    @include bp-min('sm') {
      flex-direction: row;
    }
  }
}

.space-auto {
  justify-content: space-between;
  align-items: center;
}

.wrap-sm {
  @include bp-max('md') {
    flex-wrap: wrap;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);

  &.end {
    align-items: flex-end;
    text-align: right;
  }
}

.flex-end {
  margin-inline-start: auto;
}

.text-verticle {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.punch-out {
  margin-left: calc(var(--site-gutter) * -1);
  margin-right: calc(var(--site-gutter) * -1);
}

.panel {
  background-color: var(--mono-white);
  border: 1px solid var(--mono-dark-eggshell);
  border-radius: var(--radius);
  padding: var(--space-m);
}

.text-link {
  color: var(--blue-100);
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s var(--ease-out-100),
    text-decoration-color 0.3s var(--ease-out-100);

  &:hover {
    color: var(--blue-60);
    text-decoration-color: var(--blue-60);
  }
}
